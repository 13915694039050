<template>
  <div>
    <div v-if="reportReferrer">
      <accordion headline="ACCOUNT INFORMATION" :active="true">
        <account-information :user="reportReferrer.referrer">
          <td>
            <div class="statistic">
              <p class="statistic__title text-warm">Mobile number</p>
              <h2 class="headline--md mt-4">
                {{ reportReferrer.referrer.countryCode }}{{ reportReferrer.referrer.phone }}
              </h2>
            </div>
          </td>
        </account-information>
      </accordion>

      <accordion v-if="referrerData" headline="REFERRER INFORMATION" :active="true">
        <account-information :user="referrerData.user">
          <td>
            <div class="statistic">
              <p class="statistic__title text-warm">Mobile number</p>
              <h2 class="headline--md mt-4">{{ referrerData.user.countryCode }}{{ referrerData.user.phone }}</h2>
            </div>
          </td>
        </account-information>
      </accordion>

      <accordion v-if="reportReferrer && !isKycReferralReward" headline="REFERRAL INFORMATION" :active="true">
        <referral-information :reportReferrer="reportReferrer" :reportReferredUsers="localReferredUsers">
          <template v-slot:searchBox>
            <div class="d-flex">
              <h3 class="headline--md text-bold">
                List of users used {{ reportReferrer.referrer.fullName }} referral link
              </h3>

              <search-box
                class="mr-8 search-box--gray"
                v-model="searchText"
                holder-text="Search by email"
                @on-search="onGetReferredUsers"
              />
            </div>
          </template>

          <template v-slot:pagination>
            <pagination
              v-if="isNextPage(reportReferredUsers.total)"
              v-model="params.page"
              :page-count="getPageCount(reportReferredUsers.totalPages, reportReferredUsers.total)"
              :page-range="5"
              :click-handler="onGetReferredUsers"
            ></pagination>
          </template>
        </referral-information>
      </accordion>
    </div>

    <p v-else>No data</p>
  </div>
</template>

<script>
import pagination from '@/mixins/pagination'
import handleReferrer from '@/mixins/handle-referrer'
const ReferralInformation = () => import('@/components/ReferralInformation')
const AccountInformation = () => import('@/components/AccountInformation')
const Pagination = () => import('@/components/Pagination')
const Accordion = () => import('@/components/Accordion')
const SearchBox = () => import('@/components/SearchBox')

export default {
  mixins: [pagination, handleReferrer],

  components: {
    ReferralInformation,
    AccountInformation,
    Pagination,
    Accordion,
    SearchBox
  }
}
</script>
